$(function() {
    $('.actived-interview').click(function () {
        $('.interviews').removeClass('unactived')
    });
    $('.unactived-interview').click(function () {
        $('.interviews').addClass('unactived')
    });
});
$(document).ready(function(){

    $('input#login, input#password').unbind().blur(function(){

        var id = $(this).attr('id');
        var val = $(this).val();

        switch(id) {
            case 'login':

                if(val.length > 2 && val != '') {
                    $(this).removeClass('error').addClass('not-error');
                    $(this).parent().removeClass('has-feedback has-error').addClass('has-feedback has-succsess');
                } else {
                    $(this).removeClass('not-error').addClass('error');
                    $(this).parent().removeClass('has-feedback has-succsess').addClass('has-feedback has-error');
                }
                break;

            case 'password':
                if(val.length > 2 && val != '') {
                    $(this).removeClass('error').addClass('not-error');
                    $(this).parent().removeClass('has-feedback has-error').addClass('has-feedback has-succsess');
                } else {
                    $(this).removeClass('not-error').addClass('error');
                    $(this).parent().removeClass('has-feedback has-succsess').addClass('has-feedback has-error');
                }
                break;

        } //end switch
        if($('.not-error').length === 2) {
            $('.auth-button').removeClass('disabled');
        }
    }); //end blur

    $('input#pass-reset').unbind().blur(function(){

        var id = $(this).attr('id');
        var val = $(this).val();

        switch(id) {
            case 'pass-reset':

                var res_email = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/; //regExp для email
                if(val.length != '' && res_email.test(val)) {
                    $(this).removeClass('error').addClass('not-error');
                    $(this).parent().removeClass('has-feedback has-error').addClass('has-feedback has-succsess');
                } else {
                    $(this).removeClass('not-error').addClass('error');
                    $(this).parent().removeClass('has-feedback has-succsess').addClass('has-feedback has-error');
                }
                break;
        }
        if($('.not-error').length === 1) {
            $('.auth-button').removeClass('disabled');
        }
    });

    $('input#login-reset, input#password-reset').unbind().blur(function(){

        var id = $(this).attr('id');
        var val = $(this).val();

        switch(id) {
            case 'login-reset':

                var res_login = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/; //regExp для email

                if(val.length != '' && res_login.test(val)) {
                    $(this).removeClass('error').addClass('not-error');
                    $(this).parent().removeClass('has-feedback has-error').addClass('has-feedback has-succsess');
                } else {
                    $(this).removeClass('not-error').addClass('error');
                    $(this).parent().removeClass('has-feedback has-succsess').addClass('has-feedback has-error');
                }
                break;

            case 'password-reset':
                if(val.length > 2 && val != '') {
                    $(this).removeClass('error').addClass('not-error');
                    $(this).parent().removeClass('has-feedback has-error').addClass('has-feedback has-succsess');
                } else {
                    $(this).removeClass('not-error').addClass('error');
                    $(this).parent().removeClass('has-feedback has-succsess').addClass('has-feedback has-error');
                }
                break;

        } //end switch
        if($('.not-error').length === 2) {
            $('.auth-button').removeClass('disabled');
        }
    }); //end blur
});